<template>
  <div>
    <b-alert variant="primary" show>30 초에 한번씩 갱신됩니다. <span class="pointer" @click="refreshStat">새로고침</span> <b-spinner small v-if="busy.stat"></b-spinner></b-alert>
    <c-table :table-data="items.filteredQ" :fields="fields.q" :perPage.sync="perPage" @btn-clicked="btnAction">
      <template slot="header">
        <b-checkbox class="mb-2" v-model="filtered">이슈가 있는 Queue 만 보기</b-checkbox>
      </template>
    </c-table>

<!--    <b-modal v-model="modal.detail">
      <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{item.text}}</pre>
    </b-modal>

    <b-card>
      <h4>이미지 처리</h4>
      <b-row>
        <b-col cols="2">
          <h5>1. 이미지 가져오기</h5>
          <div v-if="stat.fetch">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.fetch.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('fetch', 'waiting')">대기중</b-badge>
            {{stat.fetch.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('fetch', 'active')">실행중</b-badge>
            {{stat.fetch.active}}<br/>
            <b-badge variant="danger" @click="getDetail('fetch', 'failed')">에러</b-badge>
            {{stat.fetch.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.fetch}}</pre>
          <b-button v-if="lastType.fetch" variant="danger" @click="clearQueue('fetch', lastType.fetch)">{{lastType.fetch}} 비우기</b-button>
&lt;!&ndash;          <b-button v-if="lastType.fetch" variant="danger" @click="clearQueue('fetch', lastType.fetch, {shop_id: 5})">shop 비우기</b-button>&ndash;&gt;
        </b-col>
        <b-col cols="2">
          <h5>2. 이미지 리사이징, db에 업데이트</h5>
          <div v-if="stat.goods">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.goods.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('goods', 'waiting')">대기중</b-badge>
            {{stat.goods.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('goods', 'active')">실행중</b-badge>
            {{stat.goods.active}}<br/>
            <b-badge variant="danger" @click="getDetail('goods', 'failed')">에러</b-badge>
            {{stat.goods.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.goods}}</pre>
        </b-col>
      </b-row>
    </b-card>


    <b-card>
      <h4>실시간 재고연동</h4>
      <b-row>
        <b-col cols="2">
          <h5>재고연동</h5>
          <div v-if="stat.stock">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.stock.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('stock', 'waiting')">대기중</b-badge>
            {{stat.stock.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('stock', 'active')">실행중</b-badge>
            {{stat.stock.active}}<br/>
            <b-badge variant="danger" @click="getDetail('stock', 'failed')">에러</b-badge>
            {{stat.stock.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.stock}}</pre>
        </b-col>
        <b-col cols="2">
          <h5>우선순위 재고연동</h5>
          <div v-if="stat.stockPrior">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.stockPrior.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('stockPrior', 'waiting')">대기중</b-badge>
            {{stat.stockPrior.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('stockPrior', 'active')">실행중</b-badge>
            {{stat.stockPrior.active}}<br/>
            <b-badge variant="danger" @click="getDetail('stockPrior', 'failed')">에러</b-badge>
            {{stat.stockPrior.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.stockPrior}}</pre>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <h4>네이버 최저가</h4>
      <b-row>
        <b-col cols="2">
          <h5>1. SKU 로 최저가 검색</h5>
          <div v-if="stat.naverPrice">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.naverPrice.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('naverPrice', 'waiting')">대기중</b-badge>
            {{stat.naverPrice.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('naverPrice', 'active')">실행중</b-badge>
            {{stat.naverPrice.active}}<br/>
            <b-badge variant="danger" @click="getDetail('naverPrice', 'error')">에러</b-badge>
            {{stat.naverPrice.error}}<br/>
            <b-badge variant="warning" @click="getDetail('naverPrice', 'report')">Report</b-badge>
            {{stat.naverPrice.report}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.naverPrice}}</pre>
        </b-col>
        <b-col cols="2">
          <h5>2. 최저가 목록에 변화가 있다면, db에 업데이트</h5>
          <div v-if="stat.updatePrice">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.updatePrice.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('updatePrice', 'waiting')">대기중</b-badge>
            {{stat.updatePrice.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('updatePrice', 'active')">실행중</b-badge>
            {{stat.updatePrice.active}}<br/>
            <b-badge variant="danger" @click="getDetail('updatePrice', 'error')">에러</b-badge>
            {{stat.updatePrice.error}}<br/>
            <b-badge variant="warning" @click="getDetail('updatePrice', 'report')">Report</b-badge>
            {{stat.updatePrice.report}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.updatePrice}}</pre>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <h4>WebStore</h4>
      <b-row>
        <b-col>
          <h5>1. 웹 자원을 가져와서 S3 에 업로드</h5>
          <div v-if="stat.webStore">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.webStore.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('webStore', 'waiting')">대기중</b-badge>
            {{stat.webStore.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('webStore', 'active')">실행중</b-badge>
            {{stat.webStore.active}}<br/>
            <b-badge variant="danger" @click="getDetail('webStore', 'error')">에러</b-badge>
            {{stat.webStore.error}}<br/>
            <b-badge variant="warning" @click="getDetail('webStore', 'report')">Report</b-badge>
            {{stat.webStore.report}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.webStore}}</pre>
        </b-col>
        <b-col>
          <h5>ImageDig</h5>
          <h5>2. 이미지일 경우 webp 등 변환처리</h5>
          <div v-if="stat.imageDig">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.imageDig.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('imageDig', 'waiting')">대기중</b-badge>
            {{stat.imageDig.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('imageDig', 'active')">실행중</b-badge>
            {{stat.imageDig.active}}<br/>
            <b-badge variant="danger" @click="getDetail('imageDig', 'error')">에러</b-badge>
            {{stat.imageDig.error}}<br/>
            <b-badge variant="warning" @click="getDetail('imageDig', 'report')">Report</b-badge>
            {{stat.imageDig.report}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.imageDig}}</pre>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <h4>WebStore 후처리</h4>
      <b-row>
        <b-col cols="2">
          <h5>상품 등록(파트너)</h5>
          <div v-if="stat.register">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.register.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('register', 'waiting')">대기중</b-badge>
            {{stat.register.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('register', 'active')">실행중</b-badge>
            {{stat.register.active}}<br/>
            <b-badge variant="danger" @click="getDetail('register', 'failed')">에러</b-badge>
            {{stat.register.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.register}}</pre>
        </b-col>
        <b-col cols="2">
          <h5>상품 이미지 싱크</h5>
          <div v-if="stat.image_sync">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.image_sync.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('image_sync', 'waiting')">대기중</b-badge>
            {{stat.image_sync.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('image_sync', 'active')">실행중</b-badge>
            {{stat.image_sync.active}}<br/>
            <b-badge variant="danger" @click="getDetail('image_sync', 'failed')">에러</b-badge>
            {{stat.image_sync.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.image_sync}}</pre>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <h4>상품 상태 변경</h4>
      <b-row class="mt-3">
        <b-col cols="2">
          <h5>미사용 파트너 상품 미진열</h5>
          <div v-if="stat.display">
            <b-badge variant="secondary">전체</b-badge>
            {{stat.display.jobs}}<br/>
            <b-badge variant="info" @click="getDetail('display', 'waiting')">대기중</b-badge>
            {{stat.display.waiting}}<br/>
            <b-badge variant="success" @click="getDetail('display', 'active')">실행중</b-badge>
            {{stat.display.active}}<br/>
            <b-badge variant="danger" @click="getDetail('display', 'failed')">에러</b-badge>
            {{stat.display.failed}}<br/>
          </div>
        </b-col>
        <b-col cols="4">
          <pre style="max-height:300px;overflow-y:auto;white-space:break-spaces;">{{detail.display}}</pre>
        </b-col>
      </b-row>
    </b-card>-->

<!--    <b-card>
      <b-form inline>
        <b-form-input v-model="webStoreUrl"></b-form-input>
        <b-button @click="requestWebStore()">WebStore 요청</b-button>
      </b-form>
&lt;!&ndash;      <b-button v-for="e in queue" class="m-1" variant="warning" @click="errorDetails(e)">errorDetails {{e}}</b-button><br/>
      <b-button v-for="e in queue" class="m-1" variant="danger" @click="resetQueue(e)">reset {{e}}</b-button><br/>
      <b-form inline>
        <b-form-input v-model="webStoreGoodsNo" placeholder="goods_no"></b-form-input>
        <b-button @click="requestWebStore('useMapped')">confirmed:useMapped 요청</b-button>
      </b-form>
      <b-textarea rows="20" v-model="detailAll"></b-textarea>&ndash;&gt;
    </b-card>-->
  </div>
</template>

<script>
export default {
  name: 'QueueList',
  title: 'Queue 현황',
  data() {
    return {
      q: [
        {id: 'stock', desc: '실시간 재고연동'},
        {id: 'stockPrior', desc: '실시간 우선순위 재고연동'},
        {id: 'webStore', desc: '웹 자원을 가져와서 S3 에 업로드'},
        {id: 'imageDig', desc: '이미지일 경우 webp 등 변환처리'},
        {id: 'register', desc: 'WebStore 후처리 - 상품 등록(파트너)'},
        {id: 'image_sync', desc: 'WebStore 후처리 - 상품 이미지 싱크'},
        {id: 'setDisplayByShop', desc: '미사용 파트너 상품 미진열'},
        {id: 'returnCost', desc: '상품별 반품비 일괄설정'},
        {id: 'importOrder', desc: '신규주문 매 30초 가져오기'},
        {id: 'syncGoodsMulti', desc: '실시간 상품정보 연동'},
        {id: 'refreshES', desc: '검색엔진 상품 갱신요청'},
        {id: 'refreshESBatch', desc: '검색엔진 상품 갱신요청 Batch 작업'},

        // 이하 미사용
        // {id: 'fetch', desc: '1. 이미지 가져오기'},
        // {id: 'goods', desc: '2. 이미지 리사이징, db에 업데이트'},
        // {id: 'image', desc: '단독 이미지 리사이징'},
        // {id: 'naverPrice', desc: '1. SKU 로 최저가 검색'},
        // {id: 'updatePrice', desc: '2. 최저가 목록에 변화가 있다면, db에 업데이트'},
      ],
      qMap: {},
      group: [
        // {id: 'imageOld', desc: '이미지 처리(구)', group: ['fetch', 'goods', 'image']},
        // {id: 'syncStock', desc: '실시간 재고연동', group: ['stock', 'stockPrior']},
        // {id: 'naverPrice', desc: '네이버 최저가', group: ['naverPrice', 'updatePrice']},
        // {id: 'image', desc: 'Web Store', group: ['webStore', 'imageDig']},
        // {id: 'imageSync', desc: 'Web Store 후처리', group: ['register', 'image_sync']},
        // {id: 'display', desc: '상품 상태 변경', group: ['display']},
      ],
      groupMap: {},
      groupQMap: {},
      queue: 'fetch,goods,image,stock,stockPrior,webStore,imageDig,naverPrice,updatePrice,register,image_sync,display'.split(','),
      stat: {},
      detail: {},
      fields: {
        q: [
          {key: 'html1', label: 'ID', class: 'text-center', sortable: true},
          {key: 'type', class: 'text-center', sortable: true},
          {key: 'host', class: 'text-center', sortable: true},
          // {key: 'group', sortable: true},
          {key: 'desc', label: 'Name', sortable: true},
          {key: 'jobs', class: 'text-center', sortable: true},
          {key: 'html2', label: 'Active', class: 'text-center', sortable: true},
          {key: 'html3', label: 'Waiting', class: 'text-center', sortable: true},
          {key: 'html4', label: `Failed / Error`, class: 'text-center', sortable: true},
          {key: 'html5', label: `Stalling / Report`, class: 'text-center', sortable: true},
          {key: 'delayed', class: 'text-center', sortable: true},
          {key: 'last_id', class: 'text-center', sortable: true},
          {key: 'qid', class: 'text-center', sortable: true},
          {key: 'activeq', class: 'text-center', sortable: true},
          {
            key: '_actions', label: '', class: 'text-center w-75px', buttons: [
              {label: 'Reset', variant: 'danger', event: 'reset'},
            ]
          },
        ],
      },
      busy: {stat: false},
      modal: {detail: false},
      item: {text: ''},
      items: {
        q: [],
        filteredQ: []
      },
      perPage: 50,
      handler: null,
      lastType: {},
      webStoreUrl: '',
      webStoreGoodsNo: '',
      detailAll: '',
      filtered: true,
    }
  },
  async created() {
    this.qMap = this.$utils.arr2map(this.q, 'id');
    // this.groupMap = this.$utils.arr2map(this.group, 'id');
    // this.group.forEach(g => g.group.forEach(q => this.groupQMap[q] = g));

    this.refreshStat();
    this.handler = setInterval(this.refreshStat, 30000);
  },
  beforeDestroy() {
    clearInterval(this.handler);
  },
  watch: {
    filtered() {
      this.filterList();
    }
  },
  methods: {
    async refreshStat() {
      this.busy.stat = true;
      // let j = await this.$api.getJson('/dev/queue/stat');
      let j = await this.$api.getJson('/dev/queue/list');
      this.busy.stat = false;
      if (j) {
        j.list.forEach(e => this.assignTableData(e));
        this.items.q = j.list;
        this.filterList();
        // this.items.q = Object.entries(j.stat).map(([id, obj]) => ({id, desc: (this.qMap[id] || {}).desc, ...obj}));
        // this.items.q.forEach(e => this.assignTableData(e));
      }
    },
    assignTableData(e) {
      // e.group = (this.groupQMap[e.id] || {}).desc;
      e.desc = (this.qMap[e.id] || {}).desc
      e.html1 = `<a href="/#/dev/queue/${e.host}/${e.id}">${e.id}</a>`;
      e.html2 = `<span class="${e.active > 0 ? 'text-success bold' : ''}">${e.active}</span>`;
      e.html3 = `<span class="${e.waiting > 0 ? 'text-warning bold' : ''}">${e.waiting}</span>`;
      if (e.type === 'BeeQ') {
        e.error = e.failed;
        e.report = e.stalling;
      }
      e.html4 = `<span class="${e.error > 0 ? 'text-danger bold' : ''}">${e.error}</span>`;
      e.html5 = `<span class="${e.report > 0 ? 'text-warning' : ''}">${e.report}</span>`;
    },
    filterList() {
      this.items.filteredQ = this.filtered ? this.items.q.filter(e => e.active || e.waiting || e.error || e.report) : this.items.q;
    },
    async btnAction(row, event) {
      if (event === 'reset') {
        if (row.item.last_id != null && !confirm('last_id 가 있습니다. 해당 Queue 의 모든 데이터를 제거하시겠습니까?')) return;
        let j = await this.$api.postJson('/dev/queue/reset', {host: row.item.host, id: row.item.id});
        if (j) {
          await this.refreshStat();
        }
      }
    },


    async getDetail(q, type) {
      this.lastType[q] = type;
      let j = await this.$api.postJson('/dev/queue/queueDetail', {q, type});
      this.detail[q] = j.detail;
      this.$forceUpdate();
    },
    async clearQueue(q, type, data) {
      let j = await this.$api.postJson('/dev/queue/clearQueue', {q, type, data});
      if (j) {
        await this.refreshStat();
      }
    },
    // async resetQueue(q) {
    //   let j = await this.$api.postJson('/dev/queue/resetQueue', {q});
    //   if (j) {
    //     await this.refreshStat();
    //   }
    // },
    async requestWebStore(intent) {
      let j;
      if (intent) {
        j = await this.$api.postJson('/goods/queue/requestImageProcessing', {goods_nos: [+this.webStoreGoodsNo], intent, ignoreManual: true});
      } else {
        j = await this.$api.postJson('/goods/requestWebStore', {url: this.webStoreUrl});
      }
      if (j) {
        this.$alertTop('요청되었습니다');
      }
    },
    async errorDetails(q) {
      let j = await this.$api.postJson('/dev/queue/queueDetail', {q, type: 'error', limit: 10000});
      if (j) {
        this.detailAll = j.detail.replace(/\\n/g, '\n');
      }
    },

    async showDetail(q, type) {
      const j = await this.$api.postJson('/dev/queue/queueDetail', {q, type});
      this.item.text = j.detail;
      this.modal.detail = true;
    },
  },
}
</script>
